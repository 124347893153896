<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="6" md="6">
            <date-component v-model="date_start" label="Дата початку"
                            req
                            :class_="date_start ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="!person_hash">
            <ACC_Contractor
                :value="contractor_id"
                @autocompleteChange="ownerChange"
                color="grey"
                :select_type="'elements'"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="person_hash">
            <v-text-field type="text" filled label="Контрагент" v-model="contractor_name"
                          hide-details disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <ACC_ContractorContactPerson :value="contact_person_id" @autocompleteChange="contractChange" :person_hash="person_hash"
                                         :contractor_name="contractor_name"
                                         :contractor_id="contractor_id"
                                         :class="contact_person_id ? '' : 'req-star'" color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <ACC_Position
                :value="position_id"
                @autocompleteChange="positionChange"
                :class="position_id ? '' : 'req-star'"
                color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-select :items="categoriesSelect" type="text" filled label="Категорія" v-model="category"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="category ? '' : 'req-star'"
                      color="grey"/>
          </v-col>


        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS,
  UPDATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS,
  REMOVE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import { categoriesSelect } from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'contractor_contact_person_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_ContractorContactPerson",
  mixins: [ModalComponentMixin],
  components: {
    ACC_Contractor: () => import("@/components/accounting/autocomplite/modal/ACC_Contractor"),
    ACC_ContractorContactPerson: () => import("@/components/accounting/autocomplite/modal/ACC_ContractorContactPerson"),
    ACC_Position: () => import("@/components/accounting/autocomplite/modal/ACC_Position")
  },
  data() {
    return {
      categoriesSelect,
      contractor_name: this.item.contractor_name || null,
      person_hash: this.item.person_hash || null,
      date_start: this.item.date_start || null,
      contact_person_id: this.item.contact_person_id || null,
      category: this.item.category || null,
      position_id: this.item.position_id || null,
      contractor_id: this.item.contractor_id || null,
    }
  },
  methods: {
    contractChange(payload) {
      this.contact_person_id = (payload || {}).value || null
    },
    positionChange(payload) {
      this.position_id = (payload || {}).value || null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.contractor_name = this.item.contractor_name || null
      this.person_hash = this.item.person_hash || null
      this.contractor_id = this.item.contractor_id || null
      this.date_start = this.item.date_start || null
      this.contact_person_id = this.item.contact_person_id || null
      this.category = this.item.category || null
      this.position_id = this.item.position_id || null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення відповідальної особи контрагента (${this.short_name})`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    ownerChange(payload) {
      this.contractor_id = (payload || {})?.value || null
    },
    crud_item() {
      let error_text = `Усі поля відмічені червоним - мають бути заповнені`
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: error_text,
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        contractor_id: this.contractor_id || null,
        person_hash: this.person_hash || null,
        date_start: this.date_start || null,
        contact_person_id: this.contact_person_id || null,
        category: this.category || null,
        position_id: this.position_id || null
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.contractor_name = payload.contractor_name || null
              this.person_hash = payload.person_hash || null
              this.contractor_id = payload.contractor_id || null
              this.contact_person_id = payload.contact_person_id || null
              this.category = payload.category || null
              this.position_id = payload.position_id || null
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_CONTRACTOR_RESPONSE_PERSON_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
